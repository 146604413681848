$flashcardWrapperMargin: 20px 0 0 0;

.FlashcardArrayWrapper {
  margin: $flashcardWrapperMargin;
  padding: 20px;
  background-color: white;
  color: black;
  width: 100% !important;
  height: 100%;
}

div.fillSpace {
  width: "900px";
}

@media only screen and (max-width: 1200px) {
  p {
    font-size: 2vmin;
  }
  img {
    max-width: 250px;
  }
}

@media only screen and (max-width: 1200px) {
  p {
    font-size: 2vmin;
  }
  img {
    max-width: 250px;
  }
}

@media only screen and (max-width: 990px) {
  p {
    font-size: 1.8vmin;
  }
  img {
    max-width: 200px;
  }
}

@media only screen and (max-width: 600px) {
  p {
    font-size: 2.5vmin;
  }
}
