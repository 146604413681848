/* one red color: ff0000 */
@-webkit-keyframes pulse-red {
  0% {
    box-shadow: 0 0 8px #ff0000, inset 0 0 8px #ff0000;
  }
  50% {
    box-shadow: 0 0 16px #ff0000, inset 0 0 14px #ff0000;
  }
  100% {
    box-shadow: 0 0 8px #ff0000, inset 0 0 8px #ff0000;
  }
}

@keyframes pulse-red {
  0% {
    box-shadow: 0 0 8px #ff0000, inset 0 0 8px #ff0000;
  }
  50% {
    box-shadow: 0 0 16px #ff0000, inset 0 0 14px #ff0000;
  }
  100% {
    box-shadow: 0 0 8px #ff0000, inset 0 0 8px #ff0000;
  }
}

@-webkit-keyframes glow-red {
  0% {
    box-shadow: 0 0 18px #ff0000, inset 0 0 18px #ff0000;
  }
  50% {
    box-shadow: 0 0 116px #ff0000, inset 0 0 114px #ff0000;
  }
  100% {
    box-shadow: 0 0 18px #ff0000, inset 0 0 18px #ff0000;
  }
}

@keyframes glow-red {
  0% {
    box-shadow: 0 0 18px #ff0000, inset 0 0 18px #ff0000;
  }
  50% {
    box-shadow: 0 0 116px #ff0000, inset 0 0 114px #ff0000;
  }
  100% {
    box-shadow: 0 0 18px #ff0000, inset 0 0 18px #ff0000;
  }
}

.inner-red:hover {
  background-color: transparent;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  box-shadow: 0 0 8px #ff0000, inset 0 0 8px #ff0000;
  -webkit-animation: glow-red 2s linear 0s infinite;
  -moz-animation: glow-red 2s linear 0s infinite; /* For Firefox */
  -o-animation: glow-red 2s linear 0s infinite; /* For Opera */
  animation: glow-red 2s linear 0s infinite; /* Standard property */
}

.inner-red {
  margin: 20px;
  background-color: transparent;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  box-shadow: 0 0 8px #ff0000, inset 0 0 8px #ff0000;
  -webkit-animation: pulse-red 2s linear 1s infinite;
  -moz-animation: pulse-red 2s linear 1s infinite; /* For Firefox */
  -o-animation: pulse-red 2s linear 1s infinite; /* For Opera */
  animation: pulse-red 2s linear 1s infinite; /* Standard property */
}

.inner-red p {
  display: block;
  text-align: center;
  padding: 30px 0 0 0;
  font-family: sans-serif;
  font-size: 14px;
  text-wrap: wrap;
  color: #faa0a0;
  text-shadow: 0 0 4px #ff0000;
}
