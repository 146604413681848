@import url("https://fonts.googleapis.com/css2?family=Aladin&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather&display=swap");

html {
  /* font-size: 14px; */
  font-size: 1.5vmin;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #1f1f1f !important; */
  margin: auto 0;
  color: white;
}

a:link {
  text-decoration: none !important;
}

.div-card {
  padding: 20px;
}

.wrapper-navigation {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.centerHeight {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  color: lightgrey !important;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

@media only screen and (max-width: 950px) {
  .wrapper-navigation {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media only screen and (max-width: 600px) {
  .wrapper-navigation {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.tileHover:hover > img {
  /* filter: invert(100%);
  border: 1px solid green; */
  transform: scale(1.1);
  transition: transform 0.5s;
  /* border: 1px solid #ffffff; */
  /* padding: 5px; */
}

.blurb-loud {
  margin: 0px 0px 15px 0px;
  font-size: 2rem !important;
  font-family: "Oxanium";
}

.blurb-footer {
  margin: 25px 0px 0px 0px;
  font-size: 1rem !important;
  font-family: "Oxanium";
}
