/* one red color: FFFF00 */
@-webkit-keyframes pulse-yellow {
  0% {
    box-shadow: 0 0 8px #ffff00, inset 0 0 8px #ffff00;
  }
  50% {
    box-shadow: 0 0 16px #ffff00, inset 0 0 14px #ffff00;
  }
  100% {
    box-shadow: 0 0 8px #ffff00, inset 0 0 8px #ffff00;
  }
}

@keyframes pulse-yellow {
  0% {
    box-shadow: 0 0 8px #ffff00, inset 0 0 8px #ffff00;
  }
  50% {
    box-shadow: 0 0 16px #ffff00, inset 0 0 14px #ffff00;
  }
  100% {
    box-shadow: 0 0 8px #ffff00, inset 0 0 8px #ffff00;
  }
}

@-webkit-keyframes glow-yellow {
  0% {
    box-shadow: 0 0 18px #ffff00, inset 0 0 18px #ffff00;
  }
  50% {
    box-shadow: 0 0 116px #ffff00, inset 0 0 114px #ffff00;
  }
  100% {
    box-shadow: 0 0 18px #ffff00, inset 0 0 18px #ffff00;
  }
}

@keyframes glow-yellow {
  0% {
    box-shadow: 0 0 18px #ffff00, inset 0 0 18px #ffff00;
  }
  50% {
    box-shadow: 0 0 116px #ffff00, inset 0 0 114px #ffff00;
  }
  100% {
    box-shadow: 0 0 18px #ffff00, inset 0 0 18px #ffff00;
  }
}

.inner-yellow-BROKEN:hover {
  background-color: transparent;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  box-shadow: 0 0 8px #ffff00, inset 0 0 8px #ffff00;
  -webkit-animation: glow-yellow 2s linear 0s infinite;
  -moz-animation: glow-yellow 2s linear 0s infinite; /* For Firefox */
  -o-animation: glow-yellow 2s linear 0s infinite; /* For Opera */
  animation: glow-yellow 2s linear 0s infinite; /* Standard property */
}

.inner-yellow {
  margin: 20px;
  background-color: transparent;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  box-shadow: 0 0 8px #ffff00, inset 0 0 8px #ffff00;
  -webkit-animation: pulse-yellow 2s linear 1s infinite;
  -moz-animation: pulse-yellow 2s linear 1s infinite; /* For Firefox */
  -o-animation: pulse-yellow 2s linear 1s infinite; /* For Opera */
  animation: pulse-yellow 2s linear 1s infinite; /* Standard property */
}

.inner-yellow p {
  display: block;
  text-align: center;
  padding: 30px 0 0 0;
  font-family: sans-serif;
  font-size: 14px;
  text-wrap: wrap;
  color: #ffff00;
  text-shadow: 0 0 4px #ffff00;
}
