@-webkit-keyframes pulse-green {
  0% {
    box-shadow: 0 0 8px #008000, inset 0 0 8px #008000;
  }
  50% {
    box-shadow: 0 0 16px #008000, inset 0 0 14px #008000;
  }
  100% {
    box-shadow: 0 0 8px #008000, inset 0 0 8px #008000;
  }
}

@keyframes pulse-green {
  0% {
    box-shadow: 0 0 8px #008000, inset 0 0 8px #008000;
  }
  50% {
    box-shadow: 0 0 16px #008000, inset 0 0 14px #008000;
  }
  100% {
    box-shadow: 0 0 8px #008000, inset 0 0 8px #008000;
  }
}

@-webkit-keyframes glow-green {
  0% {
    box-shadow: 0 0 18px #008000, inset 0 0 18px #008000;
  }
  50% {
    box-shadow: 0 0 116px #008000, inset 0 0 114px #008000;
  }
  100% {
    box-shadow: 0 0 18px #008000, inset 0 0 18px #008000;
  }
}

@keyframes glow-green {
  0% {
    box-shadow: 0 0 18px #008000, inset 0 0 18px #008000;
  }
  50% {
    box-shadow: 0 0 116px #008000, inset 0 0 114px #008000;
  }
  100% {
    box-shadow: 0 0 18px #008000, inset 0 0 18px #008000;
  }
}

.inner-green-BROKEN:hover {
  background-color: transparent;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  box-shadow: 0 0 8px #008000, inset 0 0 8px #008000;
  -webkit-animation: glow-green 2s linear 0s infinite;
  -moz-animation: glow-green 2s linear 0s infinite; /* For Firefox */
  -o-animation: glow-green 2s linear 0s infinite; /* For Opera */
  animation: glow-green 2s linear 0s infinite; /* Standard property */
}

.inner-green {
  margin: 20px;
  background-color: transparent;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  box-shadow: 0 0 8px #008000, inset 0 0 8px #008000;
  -webkit-animation: pulse-green 2s linear 1.5s infinite;
  -moz-animation: pulse-green 2s linear 1.5s infinite; /* For Firefox */
  -o-animation: pulse-green 2s linear 1.5s infinite; /* For Opera */
  animation: pulse-green 2s linear 1.5s infinite; /* Standard property */
}

.inner-green p {
  display: block;
  text-align: center;
  padding: 30px 0 0 0;
  font-family: sans-serif;
  font-weight: 100;
  font-size: 14px;
  color: #008000;
  text-shadow: 0 0 4px #008000;
}
